<template>
  <el-image v-bind="$attrs" v-on="$listeners">
    <img src="@/assets/img-error.png" class="image-slot" slot="error">
  </el-image>
</template>

<script>

  export default {
    name: 'PImage'
  }
</script>

<style lang="less" scoped>
  .image-slot {
    width: 100%;
    height: 100%;
  }
</style>
