import { request } from '@/utils/axios'
import { CourseModel, getCourseModelList } from '@/model/course/CourseModel'
import { getAllUserByIds } from '@api/baseApi'
import { TOKEN_KEY } from '@api/tokenKey'
import { getAccessToken } from '@/utils/localStorage'
import { DOMIAN_URL } from '@/config'

/**
 * 根据条件查询课程
 * @param parameter
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function queryCourse(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/course/course/brief',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      if (res.success && res.list) {
        res.result = getCourseModelList(res.list)
      }
      resolve(res)
    })
  })
}

/**
 * 根据id查询课程详情
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCourseDetailById(parameter) {
  return new Promise(resolve => {
    request({
      url: `/api/v1/course/course/detail/${parameter.id}`,
      method: 'get',
      verifyToken: true
    }).then(res => {
      if (res.success && res.data) {
        let node = new CourseModel()
        res.result = node.parseNetData(res.data)
      }
      resolve(res)
    })
  })
}

/**
 * 获取课程的收藏状态、点赞状态
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCourseInfo(parameter) {
  return request({
    url: `/api/v1/course/course/userCourseInfo/${parameter.id}`,
    method: 'get',
    verifyToken: true
  })
}

/**
 * 获取课程的历史记录
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCourseWatchRecord(parameter) {
  return request({
    url: `/api/v1/personalcenter/userCourseWatchRecords/getCourseWatchRecord`,
    method: 'get',
    params: parameter,
    verifyToken: true
  })
}


/**
 * 新增课程评论
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function postCourseComments(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/course/course/comment',
      method: 'post',
      data: parameter,
      verifyToken: true
    }).then(res => {
      resolve(res)
    })
  })
}


/**
 * 根据id查询课程评论列表
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export async function getCourseCommentsList(parameter) {
  let res = await request({
    url: '/api/v1/course/course/commentList',
    method: 'get',
    params: parameter,
    verifyToken: true
  }).catch(() => {
    return Promise.resolve(res)
  })

  if (!res.success) {
    return Promise.resolve(res)
  }

  let userIds = res.list.map(item => item.userId).join(',')
  // 如果没有评论，则不需要翻译
  if (!userIds) {
    return Promise.resolve(res)
  }

  // 翻译评论
  let userRes = await getAllUserByIds({ userIds: userIds }).catch(() => {
    return Promise.resolve(res)
  })

  const userInfos = userRes.data
  if (!userInfos) {
    return Promise.resolve(res)
  }

  res.list = res.list.map(item => {
    let node = userInfos.find(user => user.userId.toString() === item.userId)
    if (node) {
      // 翻译用户名
      item.realname = node.loginName
      item.avatar = node.avatar
    }
    return item
  })
  return Promise.resolve(res)
}


export function likeCourseData(record, that) {
  return new Promise((resolve, reject) => {
    // 如果用户狂点，操作顺序为[未收藏,收藏,未收藏]，此时最后一次和第一次状态一样，那么不发送更变的请求。
    // 这里用布尔值来判断是否是偶数次
    record._isSend = !record._isSend
    // 新增防抖，避免用户狂点
    if (that.checkTimer) {
      clearTimeout(that.checkTimer)
    }
    that.checkTimer = setTimeout(() => {
      // 状态和第一次不一样时，才需要请求
      if (record._isSend) {
        postGiveCourseLike(record, that).then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        }
        ).finally((res) => {
          delete record._isSend
        })
      }
    }, 300)
  })
}

/**
 * 点赞课程
 * @param courseId
 * @param that
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function postGiveCourseLike(record, that) {
  return request({
    url: `/api/v1/course/course/like/${record.id}`,
    method: 'get',
    params: {
      actionStatus: record.likeType === '0' ? '1' : '0'
    },
    verifyToken: true
  })
}

export function collectCourseData(record, that) {
  return new Promise((resolve, reject) => {
    // 如果用户狂点，操作顺序为[未收藏,收藏,未收藏]，此时最后一次和第一次状态一样，那么不发送更变的请求。
    // 这里用布尔值来判断是否是偶数次
    record._isSend = !record._isSend
    // 新增防抖，避免用户狂点
    if (that.checkTimer) {
      clearTimeout(that.checkTimer)
    }
    that.checkTimer = setTimeout(() => {
      // 状态和第一次不一样时，才需要请求
      if (record._isSend) {
        postCollectCourse(record, that).then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        }).finally(() => {
          delete record._isSend
        })
      }
    }, 300)
  })
}

/**
 * 收藏课程
 * @param record
 * @param that
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function postCollectCourse(record, that) {
  return request({
    url: `/api/v1/personalcenter/userCourseCollectionRecords`,
    method: 'post',
    data: {
      courseId: record.id,
      // 操作状态 (添加 0/删除 1)
      actionStatus: record.collectType === '0' ? '1' : '0'
    },
    verifyToken: true
  })
}


/**
 * 根据课程id，节id获取视频id
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCourseVideoUrl(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/course/course/videoDetail',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      // if (res.success && res.data) {
      //   let node = new CourseModel()
      //   res.result = node.parseNetData(res.data)
      // }
      resolve(res)
    })
  })
}


/**
 * 根据课程id获取相关课程
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getRelatedCoursesById(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/course/course/related',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      // if (res.success && res.data) {
      //   let node = new CourseModel()
      //   res.result = node.parseNetData(res.data)
      // }
      resolve(res)
    })
  })
}

/**
 * 根据id查询课程附件
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCourseWareResourcetList(parameter) {
  return new Promise(resolve => {
    request({
      url: '/api/v1/course/course/listCourseWareResource',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      resolve(res)
    })
  })
}

/**
 * 提交播放记录
 * @param data
 */
export function commitUserCourseWatchRecords(data) {
  let token = getAccessToken()
  let url = DOMIAN_URL + '/api/v1/personalcenter/userCourseWatchRecords'
  fetch(url, {
    keepalive: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      [TOKEN_KEY]: token
    },
    body: JSON.stringify(data)
  })
}
