/*
* 是否有效
*/
export const hasEffective = {
  // 有效
  VALID: '1',
  // 无效
  INVALID: '0'
}

export const effectiveType = {
  // 已下架
  OFF_SHELF: '1',
  // 已删除
  DELETED: '2'
}

export function getEffectiveTypeStatus(val) {
  switch (val) {
  case effectiveType.OFF_SHELF:
    return '已下架'
  case effectiveType.DELETED:
    return '已删除'
  }
}
